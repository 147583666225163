<template>
  <div class="addressTime">
    <!-- Loader -->
    <q-list v-show="loading">
      <q-item v-for="n in 10" :key="n">
        <q-item-section avatar>
          <q-skeleton
            type="circle"
            width="24px"
            height="24px"
            class="q-my-sm q-ma-sm"
          />
        </q-item-section>
        <q-item-section>
          <q-skeleton type="rect" width="160px" />
        </q-item-section>
        <q-item-section side>
          <q-skeleton type="rect" width="40px" height="15px" />
        </q-item-section>
      </q-item>
    </q-list>
    <!-- Loader -->
    <q-list
      v-show="!loading"
      v-if="currentSlot && Object.keys(currentSlot).length"
    >
      <q-item
        tag="label"
        v-for="slot in currentSlot"
        :key="slot.slotId"
        v-ripple
        v-show="!loading"
      >
        <q-item-section avatar>
          <q-radio
            color="secondaryOnBody"
            v-model="selectedSlot"
            :val="slot.slotId"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label>
            {{ slot.slotTime }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-item-label v-if="slot.freeSlotCount > 0">FREE</q-item-label>
          <q-chip v-else outline color="secondary" text-color="secondary">
            {{ slot.slotFee | currency }}
          </q-chip>
        </q-item-section>
      </q-item>
    </q-list>
    <h6 v-show="!loading" class="text-center" v-else>
      No slots available for the date
    </h6>
  </div>
</template>

<script>
export default {
  name: 'AvailableSlotList',
  props: {
    slots: {
      type: Array,
      default: function() {
        return []
      },
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    selectionSlot: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    updateField: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentSlot() {
      return typeof this.slots[this.activeIndex] != 'undefined'
        ? this.slots[this.activeIndex].availableSlots
          ? this.slots[this.activeIndex].availableSlots
          : {}
        : {}
    },
    selectedSlot: {
      get() {
        return this.selectionSlot
      },
      set(val) {
        this.updateField(`${this.type}SelectedSlot`, val)
        this.updateField(`lastSelected${this.type}DayIndex`, this.activeIndex)
      },
    },
  },
}
</script>
